@font-face {
font-family: '__AkkuratLL_675ea8';
src: url(/b/a/ecom-website/ab428ad6a0953de0c88be31f1cf98b3f0821a125/_next/static/media/26a8fb288ae942e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__AkkuratLL_675ea8';
src: url(/b/a/ecom-website/ab428ad6a0953de0c88be31f1cf98b3f0821a125/_next/static/media/52aded988c1a1caf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: light;
}@font-face {font-family: '__AkkuratLL_Fallback_675ea8';src: local("Arial");ascent-override: 91.39%;descent-override: 22.87%;line-gap-override: 0.00%;size-adjust: 105.04%
}.__className_675ea8 {font-family: '__AkkuratLL_675ea8', '__AkkuratLL_Fallback_675ea8'
}.__variable_675ea8 {--font-sans: '__AkkuratLL_675ea8', '__AkkuratLL_Fallback_675ea8'
}

@font-face {
font-family: '__EditorialNew_67fb96';
src: url(/b/a/ecom-website/ab428ad6a0953de0c88be31f1cf98b3f0821a125/_next/static/media/2f495048b025fb3f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__EditorialNew_67fb96';
src: url(/b/a/ecom-website/ab428ad6a0953de0c88be31f1cf98b3f0821a125/_next/static/media/d6bb81b4bc659df4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}@font-face {font-family: '__EditorialNew_Fallback_67fb96';src: local("Arial");ascent-override: 106.99%;descent-override: 36.47%;line-gap-override: 12.16%;size-adjust: 82.25%
}.__className_67fb96 {font-family: '__EditorialNew_67fb96', '__EditorialNew_Fallback_67fb96'
}.__variable_67fb96 {--font-serif: '__EditorialNew_67fb96', '__EditorialNew_Fallback_67fb96'
}

